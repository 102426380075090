/* eslint-disable no-useless-catch */

import { QueryMongoDTO } from "@/dto/query/QueryMongo.dto";
import { QuerySequelizeDTO } from "@/dto/query/QuerySequelize.dto";
import { alertService } from "@/services/alert.service";
import { errorService } from "@/services/error.service";
import { WrappedResponse } from "@/vos/http/wrappedResponse.vo";

import { ref } from "vue";

export function useHttp() {
  const loading = ref<boolean>(false);

  /**
   * Wrappa una chiamata asincrona
   * @param fn
   * @param params
   * @returns
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const request = <AsyncFunc extends (...args: any[]) => Promise<unknown>>(
    fn: AsyncFunc,
    config = {
      showError: true,
      showSuccess: false,
      successText: "Salvataggio eseguito con successo",
    }
  ) => {
    return async (...params: Parameters<AsyncFunc>) => {
      const response: WrappedResponse<AsyncFunc> = {
        payload: null as null,
        error: null as null,
      };

      try {
        loading.value = true;
        response.payload = (await fn(...params)) as ExtractFromPromise<ReturnType<AsyncFunc>> | null | undefined;
        if (config.showSuccess) alertService.showSuccess(config.successText);
      } catch (e) {
        if (config.showError) errorService.notifyError(e);
        response.error = e;
      } finally {
        loading.value = false;
      }

      return response;
    };
  };

  function requestPaginatedMongo<AsyncFunc extends (q: QueryMongoDTO) => Promise<unknown>>(fn: AsyncFunc) {
    const skip = ref<number>(0);
    const limit = ref<number>(32);
    return (q: QueryMongoDTO) => async () => {
      // @ts-expect-error ts(2339)
      const res = await request(fn, { showError: false, showSuccess: false })({
        ...q,
        skip: skip.value,
        limit: limit.value,
      });

      // @ts-expect-error ts(2339)
      if (!res.error && res.payload?.data?.length > 0) {
        skip.value += limit.value;
      }

      return res;
    };
  }

  function requestPaginatedSequelize<AsyncFunc extends (q: QuerySequelizeDTO) => Promise<unknown>>(fn: AsyncFunc) {
    const offset = ref<number>(0);
    const limit = ref<number>(32);
    return (q: QuerySequelizeDTO) => async () => {
      if (offset.value % limit.value !== 0)
        return {
          payload: [],
          error: undefined,
        };

      // @ts-expect-error ts(2345)
      const res = await request(fn, { showError: false, showSuccess: false })({
        ...q,
        offset: offset.value,
        limit: limit.value,
      });
      offset.value += limit.value;
      return res;
    };
  }

  return {
    loading,
    request,
    requestPaginatedMongo,
    requestPaginatedSequelize,
  };
}
