import "@/assets/alert-snack.css";
import { makeBinded } from "@/proxies";

class AlertService {
  private _snack!: HTMLDivElement;
  private _timer: number | undefined = undefined;

  constructor() {
    this._init();
  }

  private _init() {
    window.addEventListener("popstate", this._hide.bind(this));
    this._snack = document.createElement("div");
    this._snack.id = "alert-snack";
    this._snack.setAttribute("class", "alert-snack animate__animated animate__fadeIn");
    this._hide();
    document.body.appendChild(this._snack);
  }

  private _hide() {
    this._snack.classList.add("hidden");
    this._snack.innerHTML = "";
  }

  private _show() {
    this._snack.classList.remove("hidden");
  }

  showError(errorText: string) {
    this._snack.innerHTML = `<p></p>`;
    clearTimeout(this._timer);
    this._snack.setAttribute("type", "error");
    this._snack.querySelector("p")!.innerHTML = errorText;
    this._show();
    // @ts-expect-error ts(2322)
    this._timer = setTimeout(this._hide.bind(this), 6000);
  }

  showSuccess(successText: string) {
    this._snack.innerHTML = `<p></p>`;
    clearTimeout(this._timer);
    this._snack.setAttribute("type", "success");
    this._snack.querySelector("p")!.innerHTML = successText;
    this._show();
    // @ts-expect-error ts(2322)
    this._timer = setTimeout(this._hide.bind(this), 4000);
  }
}

const alertService = makeBinded(AlertService);

export { alertService, AlertService };
