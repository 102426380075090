<template>
  <transition enter-active-class="animate__animated animate__fadeIn" appear v-if="noTransition">
    <div class="tw-bg-home-gradient tw-absolute tw-w-full tw-h-3/4" style="z-index: -1" />
  </transition>
  <div class="tw-bg-home-gradient tw-absolute tw-w-full tw-h-3/4" style="z-index: -1" v-else />

  <div
    id="public-layout"
    v-bind="{ ...$attrs }"
    :class="`
      tw-w-screen
      tw-h-screen
      tw-flex 
      tw-p-6
      ${justify === 'center' ? 'tw-justify-center' : justify === 'end' ? 'tw-justify-end' : 'tw-justify-start'} 
      ${align === 'center' ? 'tw-items-center' : align === 'end' ? 'tw-items-end' : 'tw-items-start'}`"
  >
    <transition enter-active-class="animate__animated animate__fadeIn" appear v-if="!noTransition">
      <slot />
    </transition>
    <div v-else>
      <slot />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    cover: {
      type: Boolean,
      default: false,
      required: false,
    },
    align: {
      type: String,
      default: "center",
    },

    justify: {
      type: String,
      default: "start",
    },
    noFooter: {
      type: Boolean,
      default: false,
    },
    "no-transition": {
      type: Boolean,
      default: false,
    },
  },
});
</script>
<style scoped></style>
