import { fcmTokensEndpoints } from "@/vos/fcm-tokens/endpoints.vo";
import { StorageFactory } from "@/factories/storage.factory";
import { httpService } from "@/services/http.service";
import { authenticationService } from "@/services/authentication.service";
import { makeBinded } from "@/proxies";

export class FCMTokensRepository {
  constructor(
    private readonly _http = httpService,
    private readonly _storageService = StorageFactory("localStorage"),
    private readonly _authService = authenticationService
  ) {}

  /**
   *
   * @returns
   */
  getCurrentToken(): string {
    return this._storageService.getItem("fcmToken") as string;
  }

  /**
   *
   * @param fcmToken
   * @returns
   */
  async setCurrentToken(fcmToken: string) {
    const accessToken = await this._authService.getAccessToken();

    this._storageService.setItem("fcmToken", fcmToken);

    return this._http.aPatch<string>(fcmTokensEndpoints.setFCMToken({}), {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: {
          device: "Web",
          fcmToken,
        },
      }),
    });
  }

  /**
   *
   * @returns
   */
  async deleteCurrentToken() {
    const fcmToken = this.getCurrentToken();
    const accessToken = await this._authService.getAccessToken();
    if (!fcmToken) return null;
    return this._http
      .aPatch<string>(fcmTokensEndpoints.deleteFCMToken({}), {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          data: {
            device: "Web",
            fcmToken,
          },
        }),
      })
      .then(() => this._storageService.removeItem("fcmToken"));
  }

  /**
   *
   * @param token
   * @returns
   */
  async updateCurrentToken(token: string) {
    if (this.getCurrentToken()) await this.deleteCurrentToken();
    return this.setCurrentToken(token);
  }
}

export const fcmTokensRepository = makeBinded(FCMTokensRepository);
