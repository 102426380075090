import { LoggingService } from "@/services/logging.service";

type Serializable = string | number | Record<string, unknown> | Array<unknown> | boolean;

/**
 * TODO: documentazione
 */
export interface SerialStorage extends Storage {
  getItem<T>(key: string): T | null;
  pickItem<T>(key: string): T | null;
  setItem(key: string, value: Serializable): void;
}

/**
 * TODO: documentazione
 */
export class SerialLocalStorage implements SerialStorage {
  constructor(private readonly _logging = new LoggingService("serial-local-storage")) {}

  clear(): void {
    localStorage.clear();
  }

  pickItem<T>(key: string): T | null {
    const item = JSON.parse(localStorage.getItem(key) || "null");
    localStorage.removeItem(key);
    return item;
  }

  getItem<T>(key: string): T | null {
    return JSON.parse(localStorage.getItem(key) || "null");
  }

  key(index: number): string | null {
    return localStorage.key(index);
  }

  removeItem(key: string): void {
    localStorage.removeItem(key);
  }

  setItem(key: string, value: Serializable): void {
    try {
      const serialized = JSON.stringify(value);
      localStorage.setItem(key, serialized);
    } catch (e) {
      this._logging.error(e);
    }
  }

  get length(): number {
    return localStorage.length;
  }
}

/**
 * TODO: documentazione
 */
export class SerialSessionStorage implements SerialStorage {
  constructor(private readonly _logging = new LoggingService("serial-session-storage")) {}

  clear(): void {
    sessionStorage.clear();
  }

  pickItem<T>(key: string): T | null {
    const item = JSON.parse(sessionStorage.getItem(key) || "null");
    sessionStorage.removeItem(item);
    return item;
  }

  getItem<T>(key: string): T | null {
    return JSON.parse(sessionStorage.getItem(key) || "null");
  }

  key(index: number): string | null {
    return sessionStorage.key(index);
  }

  removeItem(key: string): void {
    sessionStorage.removeItem(key);
  }

  setItem(key: string, value: Serializable): void {
    try {
      const serialized = JSON.stringify(value);
      sessionStorage.setItem(key, serialized);
    } catch (e) {
      this._logging.error(e);
    }
  }

  get length(): number {
    return sessionStorage.length;
  }
}
