import { stringToHex } from "@/lib/colors";

/**
 * TODO:
 * - Aggiungere bugsnag
 * - Debug dinamico (on/off mentre l'app è in esecuzione)
 * - Logging struttrato (ex: [TAG]: msg)
 */
class LoggingService {
  private readonly color: string;

  constructor(private readonly tag: string) {
    this.color = stringToHex(this.tag);
  }

  private _isDebug() {
    return true; // JSON.parse(window.localStorage.getItem("debug") || JSON.stringify(false));
  }

  log(...args: unknown[]) {
    if (this._isDebug()) console.log(`%c${this.tag}`, `color:${this.color};font-family:system-ui;font-weight:bold`, ":", ...args);
  }

  error(...args: unknown[]) {
    if (this._isDebug())
      console.error(
        `%c${this.tag}`,
        `color:${this.color};font-family:system-ui;font-weight:bold; background-color: rgba(0,0,0,0.15); padding: 4px; border-radius: 2px;`,
        ":",

        ...args
      );
  }

  warn(...args: unknown[]) {
    if (this._isDebug()) console.warn(`%c${this.tag}`, `color:${this.color};font-family:system-ui;font-weight:bold`, ":", ...args);
  }
}

export { LoggingService };
