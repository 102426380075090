class UserEndpointsVO {
  private _baseURL({ origin = process.env.VUE_APP_AMMINISTRO_API_GATEWAY, v = "v2" }: { origin?: string; v?: string }) {
    return `${origin}/api/${v}`;
  }

  /**
   *
   */
  resetPassword({ v, email }: { v?: string; email: string }) {
    return `${this._baseURL({ v })}/users/account-management/reset-password/${email}`;
  }

  /**
   *
   */
  findAllProfiles({ v }: { v?: string }) {
    return `${this._baseURL({ v })}/users/auth/profiles`;
  }

  /**
   *
   * @param param0
   * @returns
   */
  findOneCustomToken({ v }: { v?: string }) {
    return `${this._baseURL({ v })}/users/auth/custom-tokens`;
  }

  updateOwn({ v, id }: { v?: string; id: string }) {
    return `${this._baseURL({ v })}/users/${id}/own`;
  }

  findOwn({ v, id }: { v?: string; id: string }) {
    return `${this._baseURL({ v })}/users/${id}/own`;
  }

  updateAvatar({ v, id }: { v?: string; id: string }) {
    return `${this._baseURL({ v })}/users/avatar/${id}`;
  }

  deleteAvatar({ v, id }: { v?: string; id: string }) {
    return `${this._baseURL({ v })}/users/avatar/${id}`;
  }
}

const userEndpointsVO = new UserEndpointsVO();

export { UserEndpointsVO, userEndpointsVO };
