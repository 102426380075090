import { HTTPError } from "ky";

// TODO: documentazione
export class ErrorRest {
  isErrorRest(err: unknown): err is HTTPError {
    return typeof err === "object" && err !== null && "name" in err && "response" in err && "request" in err;
  }

  async parse(error: HTTPError) {
    const { status } = error.response;
    const data = await error.response.json();

    const msg = data.error.message || data.message;

    switch (status) {
      case 500:
        return "Server non disponibile, riprovare più tardi";
      case 400:
        return `Richiesta malformata <br> ${msg}`;
      case 404:
        return msg;
      case 403:
        return "Permessi insufficienti";
      case 413:
        return `Il file caricato è troppo grande <br> ${msg}`;
      default:
        return `Richiesta non valida <br> ${msg}`;
    }
  }
}

export const errorRest = new ErrorRest();
