import { errorAPIGateway } from "@/vos/errors/apiGateway.vo";
import { errorFirebase } from "@/vos/errors/firebase.vo";
import { errorDefault } from "@/vos/errors/default";
import { errorRest } from "@/vos/errors/rest";

export interface ErrorParser {
  parse(error: unknown): Promise<string | null>;
}

export function errorParserFactory(error: unknown): ErrorParser {
  if (errorAPIGateway.isErrorAPIGateway(error)) return errorAPIGateway;

  if (errorFirebase.isErrorFirebase(error)) return errorFirebase;

  if (errorRest.isErrorRest(error)) return errorRest;

  return errorDefault;
}
