import { Directive } from "vue";

function rippleEffect(el: HTMLDivElement) {
  return (event: MouseEvent) => {
    const circle = document.createElement("span");
    const diameter = Math.max(el.clientWidth, el.clientHeight);
    const radius = diameter / 2;

    circle.style.width = circle.style.height = `${diameter}px`;
    circle.style.left = `${event.clientX - (el.offsetLeft + radius)}px`;
    circle.style.top = `${event.clientY - (el.offsetTop + radius)}px`;
    circle.classList.add("ripple");

    const ripple = el.getElementsByClassName("ripple")[0];

    if (ripple) {
      ripple.remove();
    }

    el.appendChild(circle);
  };
}

/**
 * TODO: documentazione
 */
export const ripple: Directive<HTMLDivElement> = {
  mounted(el) {
    el.addEventListener("click", rippleEffect(el));
  },
};
