<template>
  <div>
    <div class="tw-px-3" v-if="scopedItems.length > 0">
      <slot :items="scopedItems" :attrs="$attrs"></slot>
    </div>

    <div v-if="loading" class="tw-w-full tw-flex tw-justify-center tw-items-center">
      <shared-progress-circular :size="24" color="rgba(107, 114, 128, 1)" />
    </div>
    <transition class="animate__animated animate__fadeIn animate__faster" v-else-if="scopedItems.length === 0 && !loading">
      <!-- eslint-disable-next-line vue/require-toggle-inside-transition -->
      <div>
        <slot name="not-found">
          <shared-not-found />
        </slot>
      </div>
    </transition>
    <div v-m_intersect="{ onVisible: updateFn }" id="trigger" v-if="!hideTrigger" />
  </div>
</template>
<script lang="ts">
import { defineComponent, PropType } from "vue";
import { useHttp } from "@/composables/useHttp";
import { QueryMongoDTO } from "@/dto/query/QueryMongo.dto";
import { QuerySequelizeDTO } from "@/dto/query/QuerySequelize.dto";

export default defineComponent({
  props: {
    query: {
      type: Object as PropType<QueryMongoDTO | QuerySequelizeDTO>,
      required: true,
    },
    request: {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      type: Function as PropType<(q: QueryMongoDTO | QuerySequelizeDTO) => Promise<any>>,
      required: true,
    },
    modelValue: {
      type: Array as PropType<unknown[]>,
      required: true,
    },
  },
  data: () => ({
    updateFn: null as null | ((...args: unknown[]) => Promise<unknown>),
    scopedItems: [] as unknown[],
    hideTrigger: false,
  }),
  setup() {
    const { requestPaginatedMongo, requestPaginatedSequelize, loading: loading } = useHttp();
    return {
      requestPaginatedSequelize,
      requestPaginatedMongo,
      loading,
    };
  },

  computed: {
    strategy() {
      return "skip" in this.query ? "mongo" : "sequelize";
    },
    requestFn() {
      return this.strategy === "mongo" ? this.requestPaginatedMongo : this.requestPaginatedSequelize;
    },
  },
  emits: ["update:modelValue", "update:pending"],

  created() {
    this.loading = true;
    this.setUpdateFn();
  },

  methods: {
    setUpdateFn() {
      // @ts-expect-error ts(2339)
      const updateFn = this.requestFn(this.request)(this.query);
      this.updateFn = async () => {
        const { payload } = await updateFn();
        this.scopedItems = [...this.scopedItems, ...payload.data];
      };
      this.hideTrigger = true;
      this.$nextTick(() => {
        this.hideTrigger = false;
      });
    },
  },
  watch: {
    query: {
      deep: true,
      handler() {
        this.scopedItems = [];
        this.setUpdateFn();
      },
    },
    modelValue: {
      deep: true,
      handler(v) {
        this.scopedItems = v;
      },
    },
    scopedItems: {
      deep: true,
      handler(v) {
        if (v.length === 0 && !this.loading) this.hideTrigger = true;
        this.$emit("update:modelValue", v);
      },
    },
    loading(v) {
      this.$emit("update:pending", v);
    },
  },
});
</script>
<style>
#trigger {
  height: 2px;
  width: 100%;
}
</style>
