import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderSlot as _renderSlot, mergeProps as _mergeProps, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6a2c51ce"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["disabled", "shape"]
const _hoisted_2 = ["disabled", "shape"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_shared_progress_circular = _resolveComponent("shared-progress-circular")!

  return (_ctx.shape !== 'text')
    ? (_openBlock(), _createElementBlock("button", _mergeProps({
        key: 0,
        class: [`tw-btn disabled:tw-opacity-50 ${_ctx.disabled ? 'tw-cursor-not-allowed' : 'tw-cursor-pointer'}`, "tw-flex tw-justify-center tw-whitespace-nowrap tw-relative tw-text-white"],
        disabled: _ctx.disabled || _ctx.loading,
        shape: _ctx.shape
      }, _ctx.$attrs), [
        _createElementVNode("div", {
          class: _normalizeClass([_ctx.loading ? null : 'tw-hidden', "tw-absolute"])
        }, [
          _createVNode(_component_shared_progress_circular, {
            color: _ctx.dark ? 'white' : 'black',
            size: 24
          }, null, 8, ["color"])
        ], 2),
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.loading ? 'tw-invisible' : null)
        }, [
          _renderSlot(_ctx.$slots, "default", {}, undefined, true)
        ], 2)
      ], 16, _hoisted_1))
    : (_openBlock(), _createElementBlock("button", _mergeProps({
        key: 1,
        class: [`tw-btn disabled:tw-opacity-50 ${_ctx.disabled ? 'tw-cursor-not-allowed' : 'tw-cursor-pointer'}`, "tw-flex tw-justify-center tw-whitespace-nowrap tw-relative"],
        disabled: _ctx.disabled || _ctx.loading,
        shape: _ctx.shape
      }, _ctx.$attrs), [
        _createElementVNode("div", {
          class: _normalizeClass([_ctx.loading ? null : 'tw-hidden', "tw-absolute"])
        }, [
          _createVNode(_component_shared_progress_circular, {
            color: _ctx.dark ? 'white' : 'black',
            size: 24
          }, null, 8, ["color"])
        ], 2),
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.loading ? 'tw-invisible' : null)
        }, [
          _renderSlot(_ctx.$slots, "default", {}, undefined, true)
        ], 2)
      ], 16, _hoisted_2))
}