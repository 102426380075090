<template>
  <div class="tw-w-full tw-flex tw-justify-center">
    <div class="tw-card tw-mx-3 tw-border-primary-light tw-flex" style="border-top-width: 6px; border-top-color: rgba(1, 149, 135, 1); width: min(800px, 100%)">
      <div class="tw-w-16 tw-h-16 tw-rounded-full tw-bg-primary-light tw-bg-opacity-10 tw-flex tw-justify-center tw-items-center">
        <i class="fas fa-search tw-text-primary-dark" style="font-size: 24px"></i>
      </div>
      <div class="tw-flex tw-flex-col tw-justify-between tw-ml-4">
        <p class="tw-text-xl">{{ title || "Nessun risultato trovato" }}</p>
        <p class="tw-mb-1">{{ text || "Prova a modificare i criteri di ricerca" }}</p>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    title: {
      type: String,
      required: false,
    },
    text: {
      type: String,
      required: false,
    },
  },
});
</script>
<style scoped></style>
