import { authenticationService } from "@/services/authentication.service";
import { Profile } from "@amministro-io-packages/auth-interfaces";

class HttpHeadersVO {
  xAmministroProfile(pr: Profile) {
    return async (headers: Record<string, string> = {}): Promise<Record<string, string>> => {
      return {
        ...headers,
        xAmministroProfile: pr.xAmministroProfile,
      };
    };
  }

  async autenticated(headers: Record<string, string> = {}, customJwt?: string): Promise<Record<string, string>> {
    return {
      ...headers,
      Authorization: `Bearer ${customJwt ?? (await authenticationService.getAccessToken())}`,
    };
  }

  async json(headers: Record<string, string> = {}): Promise<Record<string, string>> {
    return {
      ...headers,
      "Content-Type": "application/json",
    };
  }
}

const httpHeadersVO = new HttpHeadersVO();

export { httpHeadersVO, HttpHeadersVO };
