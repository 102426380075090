import { sleep } from "@/lib/utils";
import { ref, watch } from "vue";

export function useLazyLoop<T>(timing?: number) {
  const items = ref<Array<T>>([]);
  const lazyItems = ref<Array<T>>([]);

  watch(
    items,
    async () => {
      const { value: lazyValues } = lazyItems;
      const { value: itemsValues } = items;

      if (itemsValues.length < lazyValues.length) {
        lazyValues.length = itemsValues.length;
      }

      for (let i = 0; i < itemsValues.length; i++) {
        const item = items.value[i];
        if (JSON.stringify(item) !== JSON.stringify(lazyValues[i])) {
          lazyValues[i] = item;
          if (i + 1 === lazyValues.length) await sleep(timing || 200);
        }
      }
    },
    {
      immediate: true,
    }
  );

  return {
    items,
    lazyItems,
  };
}
