import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-102228f2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "tw-flex tw-items-center" }
const _hoisted_2 = ["data-disabled"]
const _hoisted_3 = { class: "tw-mx-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass([_ctx.disabled ? 'tw-cursor-not-allowed' : 'tw-cursor-pointer', "tw-relative tw-h-3 tw-flex tw-items-center tw-switch-rail"]),
      "data-disabled": _ctx.disabled,
      onClick: _cache[0] || (_cache[0] = 
        () => {
          !_ctx.disabled ? (_ctx.checked = !_ctx.checked) : void 0;
        }
      )
    }, [
      _createElementVNode("div", {
        class: _normalizeClass(["tw-w-9 tw-h-3 tw-rounded-full tw-bg-gray-300", _ctx.checked ? 'tw-bg-primary-light' : 'tw-left-0'])
      }, null, 2),
      _createElementVNode("div", {
        class: _normalizeClass(["tw-w-5 tw-h-5 tw-rounded-full tw-bg-white tw-absolute -tw-top-1 switch-ball", _ctx.checked ? 'checked tw-bg-primary-dark' : ''])
      }, null, 2)
    ], 10, _hoisted_2),
    _createElementVNode("div", _hoisted_3, [
      _renderSlot(_ctx.$slots, "default", {}, () => [
        _createTextVNode(_toDisplayString(_ctx.label), 1)
      ], true)
    ])
  ]))
}