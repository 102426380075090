import { StorageFactory } from "@/factories/storage.factory";
import { authenticationService } from "@/services/authentication.service";
import { authorizeService } from "@/services/authorization.service";
import { bindingService } from "@/services/bindings.service";
import { Profile } from "@amministro-io-packages/auth-interfaces";
import { User } from "firebase/auth";
import { defineStore } from "pinia";
import { computed, ref, watch } from "vue";

const storage = StorageFactory("localStorage");

export const useUserStore = defineStore("user", () => {
  const pending = ref<boolean>(true);

  const set = (v: boolean) => () => {
    pending.value = v;
  };

  authenticationService.addEventListener("user", set(false));
  authenticationService.addEventListener("error", set(false));
  authenticationService.addEventListener("pending", set(true));

  watch(pending, (v) => {
    if (v) {
      storage.setItem("pending", v);
    } else {
      storage.removeItem("pending");
    }
  });

  const user = ref<User & { profiles: Profile[] }>();
  const profiles = computed(() => user.value?.profiles);
  const ctxProfiles = computed(() =>
    user.value?.profiles.map((pr) => ({
      ...pr,
      apps: bindingService.appsFromProfile(pr).filter(({ app }) => authorizeService.setStrategy(app!).authorizeProfile(pr)),
    }))
  );

  const profilesGroups = computed(() => {
    return (
      ctxProfiles.value &&
      ctxProfiles.value.reduce((acc: Array<Array<Profile>>, curr: Profile) => {
        if (acc.length === 0) acc.push([curr]);
        else if (acc.length > 0 && curr.role.split("_")[0] !== acc[acc.length - 1][0].role.split("_")[0]) {
          acc[acc.length - 1] = acc[acc.length - 1].sort((a, b) => a.company_name.localeCompare(b.company_name));
          acc.push([curr]);
        } else acc[acc.length - 1].push(curr);
        return acc;
      }, [])
    );
  });

  authenticationService.addEventListener("user", (evt) => {
    user.value = (evt as CustomEvent<User & { profiles: Profile[] }>).detail;
  });

  const error = ref<unknown>(null);
  authenticationService.addEventListener("error", (evt) => {
    error.value = (evt as CustomEvent<unknown>).detail;
    setTimeout(() => {
      error.value = null;
    }, 3000);
  });

  const customToken = ref<string | null>(null);
  authenticationService.addEventListener("token", (evt) => {
    customToken.value = (evt as CustomEvent<string>).detail;
  });

  authenticationService.addEventListener("device-id", (evt) => {
    storage.setItem("deviceId", (evt as CustomEvent<string>).detail);
  });

  const getDeviceId = () => storage.pickItem("deviceId") as string | null;

  return {
    customToken,
    pending,
    error,
    user,
    profiles,
    ctxProfiles,
    profilesGroups,
    signIn: authenticationService.signIn,
    signOut: authenticationService.signOut,
    getAccessToken: authenticationService.getAccessToken,
    getDeviceId,
  };
});
