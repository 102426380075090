export enum API_GATEWAY_STATUS_CODE {
  UNAUTHORIZED = 401,
  FORBIDDEN = 403,
  NOT_FOUND = 404,
  TOO_MANY_REQUESTS = 429,
  PRECONDITION_FAILED = 412,
  INTERNAL_SERVER_ERROR = 500,
}

export class ErrorAPIGatewayDTO {
  code!: API_GATEWAY_STATUS_CODE;
  message!: string;
  errorCode?: number;
  data?: Record<string, unknown>;

  constructor(opts?: Record<string, any>) {
    // @ts-expect-error ts(2339)
    if (opts) Object.keys(this).forEach((key) => (this[key] = opts[key]));
  }
}
