import { SerialLocalStorage, SerialSessionStorage, SerialStorage } from "@/services/storage.service";

const cache = {
  serialLocalStorage: null as null | SerialLocalStorage,
  serialSessionStorage: null as null | SerialSessionStorage,
};

/**
 *
 * @param type
 * @param options
 * @returns
 */
export function StorageFactory(type: "localStorage" | "sessionStorage"): SerialStorage {
  switch (type) {
    case "localStorage":
      if (!cache["serialLocalStorage"]) cache["serialLocalStorage"] = new SerialLocalStorage();
      return cache.serialLocalStorage;
    case "sessionStorage":
      if (!cache["serialSessionStorage"]) cache["serialSessionStorage"] = new SerialSessionStorage();
      return cache.serialSessionStorage;
  }
}
