<template>
  <div class="lds-ring spinner">
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    size: {
      type: Number,
      required: true,
    },
    color: {
      type: String,
      required: true,
    },
  },

  computed: {
    radius() {
      return (this.size || 64) + "px";
    },
  },
});
</script>
<style scoped>
.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 4px solid #fff;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fff transparent transparent transparent;
}

.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}

.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}

.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes lds-ring {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

.spinner {
  display: flex;
  align-items: center;
  justify-content: center;
  width: v-bind(radius) !important;
  height: v-bind(radius) !important;
}

.spinner div {
  border-color: v-bind(color) transparent transparent transparent;
  width: v-bind(radius) !important;
  height: v-bind(radius) !important;
}
</style>
