import { FirebaseApp, initializeApp } from "firebase/app";
import { deleteToken, getMessaging, getToken, isSupported, Messaging, onMessage } from "firebase/messaging";
// @ts-expect-error ts(2339)
import registerServiceWorker from "service-worker-loader!@/workers/fcm.worker";
import { FCMTokensRepository, fcmTokensRepository } from "@/repositories/fcmTokens.repository";
import { makeBinded } from "@/proxies";

class FCMListenerService extends EventTarget {
  private readonly messaging: Messaging | null;
  private swReg: ServiceWorkerRegistration | null = null;
  private _interval: Nullish<ReturnType<typeof setInterval>> = null;

  constructor(
    private readonly _fcmTokensRepository: FCMTokensRepository = fcmTokensRepository,

    private readonly app: FirebaseApp = initializeApp(
      {
        appId: process.env.VUE_APP_FB_APPID!,
        projectId: process.env.VUE_APP_FB_PROJECTID!,
        apiKey: process.env.VUE_APP_FB_APIKEY!,
        authDomain: process.env.VUE_APP_FB_AUTHDOMAIN!,
        messagingSenderId: process.env.VUE_APP_FB_SENDERID,
      },
      "firebase-notifications"
    )
  ) {
    super();
    try {
      this.messaging = getMessaging(this.app);
      this.listenForPermissionsChange();
    } catch (e) {
      this.messaging = null;
    }
  }

  private listenForPermissionsChange() {
    try {
      navigator.permissions.query({ name: "notifications" }).then((notificationPerm) => {
        notificationPerm.onchange = (evt) => {
          this.dispatchEvent(
            new CustomEvent("notifications-permission", {
              // @ts-expect-error ts(2339)
              detail: evt.target?.state,
            })
          );
        };
      });
    } catch (e) {
      // DO NOTHING
    }
  }

  async stopListening() {
    if (!this.messaging) return;
    try {
      await deleteToken(this.messaging);
    } catch (_e) {
      // DO NOTHING
    }
    try {
      await this.swReg?.unregister();
    } catch (_e) {
      // DO NOTHING
    }
    try {
      return this._fcmTokensRepository.deleteCurrentToken();
    } catch (_e) {
      // DO NOTHING
    }
  }

  private _poll_for_token_change(messaging: Messaging, worker: ServiceWorkerRegistration) {
    if (this._interval) clearInterval(this._interval);
    this._interval = setInterval(() => {
      getToken(messaging, {
        serviceWorkerRegistration: worker,
      }).then((token: string) => {
        const _currentToken = fcmTokensRepository.getCurrentToken();
        if (token !== _currentToken) {
          this._fcmTokensRepository.updateCurrentToken(token);
        }
      });
    }, 30 * 1000);
  }

  /**
   *
   */
  async listen() {
    if (!this.messaging) return;
    this.swReg = (await registerServiceWorker({ scope: "/" })) as ServiceWorkerRegistration;

    onMessage(this.messaging, (evt) => {
      this.dispatchEvent(new CustomEvent("new-notification", { detail: evt }));
    });

    getToken(this.messaging, {
      serviceWorkerRegistration: this.swReg,
    }).then(this._fcmTokensRepository.updateCurrentToken);

    this._poll_for_token_change(this.messaging, this.swReg);
  }

  /**
   *
   */
  async askPermissions() {
    return Notification.requestPermission();
  }

  isSupported() {
    return isSupported();
  }
}

const fcmListenerService = makeBinded(FCMListenerService);

export { fcmListenerService, FCMListenerService };
