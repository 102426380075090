export enum FIREBASE_ERROR_CODE {
  WRONG_PASSWORD = "auth/wrong-password",
  EMAIL_NOT_FOUND = "auth/user-not-found",
  USER_DISABLED = "auth/user-disabled",
}

export class ErrorFirebaseDTO {
  code!: FIREBASE_ERROR_CODE;
  customData: unknown;
  name!: "FirebaseError";

  constructor(opts?: Record<string, any>) {
    // @ts-expect-error ts(2339)
    if (opts) Object.keys(this).forEach((key) => (this[key] = opts[key]));
  }
}
