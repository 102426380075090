/**
 * TODO: documentazione
 * @param str
 */
export const capitalize = <T extends string>(str: T): Capitalize<Lowercase<T>> => {
  return str.charAt(0).toUpperCase().concat(str.slice(1).toLowerCase()) as Capitalize<Lowercase<T>>;
};

/**
 * TODO: documentazione
 * @param str
 */
export const upperCase = <T extends string>(str: T): Uppercase<T> => {
  return str.toUpperCase() as Uppercase<T>;
};

/**
 * TODO: documentazione
 * @param str
 */
export const lowerCase = <T extends string>(str: T): Lowercase<T> => {
  return str.toLowerCase() as Lowercase<T>;
};

/**
 * TODO: documentazione
 * @param str
 */
export const snakeToCamelCase = <T extends string>(str: T): SnakeToCamelCase<T> => {
  return str
    .split("_")
    .map((e) => capitalize(e))
    .join("") as SnakeToCamelCase<T>;
};
