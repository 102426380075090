function baseURL({ origin = process.env.VUE_APP_AMMINISTRO_API_GATEWAY, v = "v2" }: { origin?: string; v?: string }) {
  return `${origin}/api/${v}`;
}

export const fcmTokensEndpoints = {
  deleteFCMToken({ v }: { v?: string }) {
    return `${baseURL({ v })}/fcm-tokens/remove`;
  },

  setFCMToken({ v }: { v?: string }) {
    return `${baseURL({ v })}/fcm-tokens/add`;
  },
};
