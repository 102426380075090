import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Transition as _Transition, withCtx as _withCtx, createBlock as _createBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "tw-px-3"
}
const _hoisted_2 = {
  key: 1,
  class: "tw-w-full tw-flex tw-justify-center tw-items-center"
}
const _hoisted_3 = {
  key: 3,
  id: "trigger"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_shared_progress_circular = _resolveComponent("shared-progress-circular")!
  const _component_shared_not_found = _resolveComponent("shared-not-found")!
  const _directive_m_intersect = _resolveDirective("m_intersect")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.scopedItems.length > 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _renderSlot(_ctx.$slots, "default", {
            items: _ctx.scopedItems,
            attrs: _ctx.$attrs
          })
        ]))
      : _createCommentVNode("", true),
    (_ctx.loading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_shared_progress_circular, {
            size: 24,
            color: "rgba(107, 114, 128, 1)"
          })
        ]))
      : (_ctx.scopedItems.length === 0 && !_ctx.loading)
        ? (_openBlock(), _createBlock(_Transition, {
            key: 2,
            class: "animate__animated animate__fadeIn animate__faster"
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", null, [
                _renderSlot(_ctx.$slots, "not-found", {}, () => [
                  _createVNode(_component_shared_not_found)
                ])
              ])
            ]),
            _: 3
          }))
        : _createCommentVNode("", true),
    (!_ctx.hideTrigger)
      ? _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_3, null, 512)), [
          [_directive_m_intersect, { onVisible: _ctx.updateFn }]
        ])
      : _createCommentVNode("", true)
  ]))
}