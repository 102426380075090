import { FirebaseApp, initializeApp } from "firebase/app";
import {
  Auth as FirebaseAuth,
  EmailAuthProvider,
  getAuth,
  onIdTokenChanged,
  reauthenticateWithCredential,
  signInWithEmailAndPassword,
  signInWithCustomToken,
  updatePassword,
  reload,
} from "firebase/auth";
import { createHiddenframe } from "@/lib/dom";
import { httpService } from "./http.service";
import { Profile } from "@amministro-io-packages/auth-interfaces";
import { userEndpointsVO } from "@/vos/user/endpoints.vo";
import { makeBinded } from "@/proxies";
import { uuid } from "@/lib/random";

const FIREBASE_AUTH_CONFIGURATION = {
  appId: process.env.VUE_APP_FB_APPID!,
  projectId: process.env.VUE_APP_FB_PROJECTID!,
  apiKey: process.env.VUE_APP_FB_APIKEY!,
  authDomain: process.env.VUE_APP_FB_AUTHDOMAIN!,
} as const;

const APP_LIST = [
  process.env.VUE_APP_ADMINISTRATION_URL,
  process.env.VUE_APP_AMMINISTROIO_URL,
  process.env.VUE_APP_ALFRED_URL,
  process.env.VUE_APP_CRUSCOTTO_URL,
  process.env.VUE_APP_INSERVIZIO_URL,
  process.env.VUE_APP_CRMSFERA_URL,
  process.env.VUE_APP_CRMCITYCLOUD_URL,
  process.env.VUE_APP_CRMVALORE24_URL,
  process.env.VUE_APP_CRMCONDOMINIO_URL,
  process.env.VUE_APP_ARCHIVIOPLUS_URL,
  process.env.VUE_APP_TEAPOT_URL,
  process.env.VUE_APP_DOORBELL_URL,
  process.env.VUE_APP_NOTIFICOONLINE_URL,
].filter((e) => e);

const TENANT_CONFIGURATION = {
  tenantId: process.env.VUE_APP_TENANT_ID!,
} as const;

const REFRESH_CONFIGURATION = {
  rate: 60000,
};

interface IAuthentication {
  signIn(...args: unknown[]): Promise<unknown>;
  signOut(...args: unknown[]): Promise<unknown>;
}

export class FirebaseAuthService extends EventTarget implements IAuthentication {
  private readonly auth: FirebaseAuth;
  private readonly app: FirebaseApp;

  constructor(
    private readonly _appConfig = FIREBASE_AUTH_CONFIGURATION,
    private readonly _appList = APP_LIST,
    private readonly _tenantConfig = TENANT_CONFIGURATION,
    private readonly _refreshConfig = REFRESH_CONFIGURATION,
    private readonly _http = httpService,
    private readonly _endpoints = userEndpointsVO
  ) {
    super();
    this.app = initializeApp(this._appConfig);
    this.auth = getAuth(this.app);
    this.auth.tenantId = this._tenantConfig.tenantId;
    this._startSession();
  }

  private _startSession() {
    this._addSessionCheckers();
  }

  private _addSessionCheckers() {
    this._addOnRefreshListener();
    this._addOnVisibleListener();
    this._addOnlineListener();
    this._addOnTimeoutOutListener();
  }

  private _addOnRefreshListener() {
    onIdTokenChanged(this.auth, async (user) => {
      if (user) {
        try {
          const [{ data: profiles }, { data: token }] = await Promise.all([this.getProfiles(), this.getCustomToken()]);

          this._notifyUpdate({
            user: { ...user, profiles },
            token,
          });
        } catch (e) {
          this.dispatchEvent(new CustomEvent("error", { detail: e }));
          this._notifyUpdate({ user: null, token: null });
        }
      } else {
        this._notifyUpdate({ user: null, token: null });
      }
    });
  }

  private _notifyUpdate<U, T>({ user, token }: { user: U; token: T }) {
    this.dispatchEvent(new CustomEvent("user", { detail: user }));
    this.dispatchEvent(new CustomEvent("token", { detail: token }));
  }

  private _addOnlineListener() {
    window.addEventListener("online", () => this.refresh());
  }

  private _addOnVisibleListener() {
    document.addEventListener("visibilitychange", () => this.refresh);
  }

  private _addOnTimeoutOutListener() {
    setInterval(() => this.refresh(), this._refreshConfig.rate);
  }

  private _logoutFromApp() {
    return this.auth.signOut();
  }

  private async _logoutFromApps() {
    await Promise.all(
      this._appList.map(
        (app) =>
          new Promise((res) => {
            const iframe = createHiddenframe([app, "logout.html"].join("/"));
            addEventListener(
              "message",
              ({ data }) => {
                if (data === "finished-logout") {
                  iframe.remove();
                  res(null);
                }
              },
              { once: true }
            );
          })
      )
    );
  }

  async refresh(forceRefresh = false) {
    const user = this.auth.currentUser;
    if (user) {
      await user.getIdToken(forceRefresh);
      await reload(user);
    }
  }

  public async signIn(email: string, password: string): Promise<void> {
    try {
      this.dispatchEvent(new CustomEvent("pending"));
      await signInWithEmailAndPassword(this.auth, email, password);
    } catch (e) {
      this.dispatchEvent(new CustomEvent("error", { detail: e }));
    }
  }

  public async signInWithCustomToken(customToken: string): Promise<void> {
    try {
      this.dispatchEvent(new CustomEvent("pending"));
      await signInWithCustomToken(this.auth, customToken);
    } catch (e) {
      this.dispatchEvent(new CustomEvent("error", { detail: e }));
    }
  }

  public async signOut(inplace = false): Promise<void> {
    if (inplace) {
      this.dispatchEvent(new CustomEvent("pending"));
      await this._logoutFromApp();
    }
    await this._logoutFromApps();
  }

  public getAccessToken() {
    if (this.auth.currentUser) {
      return this.auth.currentUser.getIdToken();
    } else {
      return new Promise<string>((r) => r(""));
    }
  }

  public getUser() {
    return this.auth.currentUser;
  }

  async resetPassword(email: string) {
    return this._http.aGet<string>(this._endpoints.resetPassword({ email }));
  }

  async changePassword(oldPassword: string, newPassword: string) {
    const user = this.auth.currentUser!;
    const credentials = EmailAuthProvider.credential(user.email!, oldPassword);
    await reauthenticateWithCredential(user, credentials);
    await updatePassword(user, newPassword);
  }

  async getProfiles() {
    return this._http.aGet<Profile[]>(this._endpoints.findAllProfiles({}), {
      headers: {
        Authorization: `Bearer ${await this.getAccessToken()}`,
      },
    });
  }

  async getCustomToken() {
    return this._http.aGet<unknown>(this._endpoints.findOneCustomToken({}), {
      headers: {
        Authorization: `Bearer ${await this.getAccessToken()}`,
      },
    });
  }

  generateDeviceId() {
    const GUID = uuid();
    this.dispatchEvent(new CustomEvent("device-id", { detail: GUID }));
    return GUID;
  }
}

export const authenticationService = makeBinded(FirebaseAuthService);
