<template>
  <div>
    <slot :lazyItems="lazyItems" />
  </div>
</template>
<script lang="ts">
import { useLazyLoop } from "@/composables/useLazyLoop";
import { defineComponent, PropType } from "vue";

export default defineComponent({
  props: {
    items: {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      type: Array as PropType<any[]>,
      required: true,
    },
    delay: {
      type: Number,
      required: false,
      default: 50,
    },
  },
  setup(props) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const { lazyItems, items: scopedItems } = useLazyLoop<any>(props.delay);
    return {
      scopedItems,
      lazyItems,
    };
  },

  watch: {
    items: {
      handler(v) {
        this.scopedItems = v;
      },
      immediate: true,
      deep: true,
    },
  },
});
</script>
