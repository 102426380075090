import { errorParserFactory } from "@/factories/errorParser.factory";
import { makeBinded } from "@/proxies";
import { alertService } from "@/services/alert.service";
import { LoggingService } from "@/services/logging.service";

class ErrorService {
  constructor(
    private readonly _loggingService = new LoggingService("error-service"),
    private readonly _alertService = alertService,
    private readonly _errorParserFactory = errorParserFactory
  ) {}

  parseError(error: unknown) {
    const parser = this._errorParserFactory(error);
    return parser.parse(error);
  }

  async notifyError(error: unknown) {
    const msg = await this.parseError(error);
    if (msg) this._alertService.showError(msg);
    this._loggingService.error(error);
  }
}

const errorService = makeBinded(ErrorService);

export { ErrorService, errorService };
