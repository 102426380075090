import "vuetify/styles";
import "@/assets/v-text-field-fix.css";
import { createVuetify } from "vuetify";
import * as components from "vuetify/lib/components/index.mjs";
import * as directives from "vuetify/lib/directives/index.mjs";
import { mdi } from "vuetify/iconsets/mdi";
import { fa } from "vuetify/iconsets/fa";

const vuetify = createVuetify({
  components,
  directives,
  theme: {
    defaultTheme: "light",
    themes: {
      light: {
        colors: {
          primary: "#019587",
          organization: "#78C0E0",
        },
      },
    },
  },
  icons: {
    defaultSet: "mdi",
    sets: {
      mdi,
      fa,
    },
  },
});

export { vuetify };
