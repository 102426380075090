import { ErrorFirebaseDTO, FIREBASE_ERROR_CODE } from "@/dto/errors/ErrorFirebase.dto";

// TODO: documentazione
export class ErrorFirebase {
  isErrorFirebase(err: unknown): err is ErrorFirebaseDTO {
    return typeof err === "object" && err !== null && "name" in err && err.name === "FirebaseError";
  }

  async parse(error: ErrorFirebaseDTO) {
    const { code } = error;

    switch (code) {
      case FIREBASE_ERROR_CODE.EMAIL_NOT_FOUND:
      case FIREBASE_ERROR_CODE.WRONG_PASSWORD:
        return "Credenziali errate";
      case FIREBASE_ERROR_CODE.USER_DISABLED:
        return "Il tuo utente è stato disabilitato";
      default:
        return "Si è verificato un errore di sessione, riprovare";
    }
  }
}

export const errorFirebase = new ErrorFirebase();
