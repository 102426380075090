export const BinderHandler: ProxyHandler<Record<string | symbol, any>> = {
  get(target, p) {
    if (typeof target[p] === "function") {
      target[p] = (target[p] as (...args: any[]) => any).bind(target);
    }
    // @ts-expect-error ts(2556)
    // eslint-disable-next-line prefer-rest-params
    return Reflect.get(...arguments);
  },
};

// eslint-disable-next-line @typescript-eslint/ban-types
export function makeBinded<T extends object>(Target: { new (): T }): T {
  const binded = new Proxy(new Target(), BinderHandler) as T;
  return binded;
}
