<template>
  <img :src="$env.VUE_APP_AMMINISTROIO_LOGO_MINI" v-bind="$attrs" class="loading" />
</template>
<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "shared-loading",
});
</script>
<style scoped>
.loading {
  object-fit: contain;
  width: 65px;
  height: 65px;
}
</style>
