<template>
  <button
    v-if="shape !== 'text'"
    :class="`tw-btn disabled:tw-opacity-50 ${disabled ? 'tw-cursor-not-allowed' : 'tw-cursor-pointer'}`"
    class="tw-flex tw-justify-center tw-whitespace-nowrap tw-relative tw-text-white"
    :disabled="disabled || loading"
    :shape="shape"
    v-bind="$attrs"
  >
    <div :class="loading ? null : 'tw-hidden'" class="tw-absolute">
      <shared-progress-circular :color="dark ? 'white' : 'black'" :size="24" />
    </div>
    <div :class="loading ? 'tw-invisible' : null">
      <slot />
    </div>
  </button>
  <button
    v-else
    :class="`tw-btn disabled:tw-opacity-50 ${disabled ? 'tw-cursor-not-allowed' : 'tw-cursor-pointer'}`"
    class="tw-flex tw-justify-center tw-whitespace-nowrap tw-relative"
    :disabled="disabled || loading"
    :shape="shape"
    v-bind="$attrs"
  >
    <div :class="loading ? null : 'tw-hidden'" class="tw-absolute">
      <shared-progress-circular :color="dark ? 'white' : 'black'" :size="24" />
    </div>
    <div :class="loading ? 'tw-invisible' : null">
      <slot />
    </div>
  </button>
</template>
<script lang="ts">
import { defineComponent, PropType } from "vue";

export default defineComponent({
  props: {
    size: {
      type: String,
      required: false,
      default: "medium",
    },
    light: {
      type: Boolean,
      required: false,
      default: false,
    },
    dark: {
      type: Boolean,
      required: false,
      default: true,
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
    shape: {
      type: String as PropType<"text" | "flat" | "default">,
      required: false,
      default: "default",
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
});
</script>
<style scoped>
.btn {
  text-transform: uppercase !important;
}
</style>
