<template>
  <div class="tw-flex tw-items-center">
    <div
      :class="disabled ? 'tw-cursor-not-allowed' : 'tw-cursor-pointer'"
      class="tw-relative tw-h-3 tw-flex tw-items-center tw-switch-rail"
      :data-disabled="disabled"
      @click="
        () => {
          !disabled ? (checked = !checked) : void 0;
        }
      "
    >
      <div class="tw-w-9 tw-h-3 tw-rounded-full tw-bg-gray-300" :class="checked ? 'tw-bg-primary-light' : 'tw-left-0'"></div>
      <div class="tw-w-5 tw-h-5 tw-rounded-full tw-bg-white tw-absolute -tw-top-1 switch-ball" :class="checked ? 'checked tw-bg-primary-dark' : ''"></div>
    </div>
    <div class="tw-mx-3">
      <slot>
        {{ label }}
      </slot>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  data: () => ({
    checked: false,
  }),

  props: {
    modelValue: {
      type: Boolean,
      required: false,
    },
    label: {
      type: String,
      required: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  emits: ["update:modelValue"],

  watch: {
    modelValue: {
      handler(v) {
        this.checked = v;
      },
      immediate: true,
    },
    checked(v) {
      this.$emit("update:modelValue", v);
    },
  },
});
</script>
<style scoped>
.switch-ball.checked {
  transform: translate(1rem);
  transition: transform linear 0.1s;
}

.switch-ball {
  transform: translate(0rem);
  transition: transform linear 0.1s;
  box-shadow: black 1px 1px 6px -3px;
}

.switch-rail[data-disabled="true"]::before {
  height: 10px;
  width: 100%;
  background-color: red;
}
</style>
