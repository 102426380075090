/**
 *
 * @param src
 * @returns
 */
export function createHiddenframe(src?: string) {
  const iframe = document.createElement("iframe");
  if (src) iframe.src = iframe.id = src;
  iframe.style.width = iframe.style.height = "0px";
  document.body.appendChild(iframe);
  return iframe;
}
