
import { defineComponent } from "vue";

const __default__ = defineComponent({
  props: {
    size: {
      type: Number,
      required: true,
    },
    color: {
      type: String,
      required: true,
    },
  },

  computed: {
    radius() {
      return (this.size || 64) + "px";
    },
  },
});

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "4d4a9229": (_ctx.radius),
  "e3d70a28": (_ctx.color)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__