import { API_GATEWAY_STATUS_CODE, ErrorAPIGatewayDTO } from "@/dto/errors/ErrorAPIGateway.dto";

// TODO: documentazione
export class ErrorAPIGateway {
  // Type guard
  isErrorAPIGateway(err: unknown): err is ErrorAPIGatewayDTO {
    return typeof err === "object" && err !== null && err instanceof ErrorAPIGatewayDTO;
  }

  async parse(error: ErrorAPIGatewayDTO) {
    const { code } = error;

    switch (code) {
      case API_GATEWAY_STATUS_CODE.PRECONDITION_FAILED:
      case API_GATEWAY_STATUS_CODE.FORBIDDEN:
        return `Hai perso l'accesso ad una o più parti di ${process.env.VUE_APP_DISPLAY_NAME}`;
      case API_GATEWAY_STATUS_CODE.INTERNAL_SERVER_ERROR:
        return "Server non disponibile. Rirovare più tardi";
      case API_GATEWAY_STATUS_CODE.NOT_FOUND:
        return "Risorsa non trovata";
      case API_GATEWAY_STATUS_CODE.TOO_MANY_REQUESTS:
        return "Rate limit raggiunto, riprovare più tardi";
      case API_GATEWAY_STATUS_CODE.UNAUTHORIZED:
        return `Non hai più accesso ${process.env.VUE_APP_DISPLAY_NAME}`;
      default:
        return "Si è verificato un errore con il server";
    }
  }
}

export const errorAPIGateway = new ErrorAPIGateway();
