import { NotificationFCMDTO, NotificationType } from "@/dto/notifications/NotificationFCM.dto";
import { makeBinded } from "@/proxies";

class FCMHandlerService {
  /**
   * Dato un tipo di notifica, ritorna la querty serializzata con le info necessarie per essere gestita
   */
  buildNotificationQuery(data: NotificationFCMDTO["data"]): string {
    const { notificationType, profileID, entityIDs, notificationBody } = data;

    switch (notificationType) {
      /**
       * Ingressi: necessario specificare il body della notifica
       */
      case "new_sys_entry_from_org_spl_usr_to_org_usr":
      case "new_sys_entry_from_spl_to_spl":
        return new URLSearchParams({ notificationType, profileID, entityIDs, notificationBody }).toString();

      /**
       * Ordini: necessario specificare la categoria
       */
      case "new_order_assigned_from_org_to_spl":
      case "new_order_chat_message_from_org_spl_to_org_spl":
      case "order_askedprice_rejected_from_org_to_spl":
      case "order_askedprice_setted_from_spl_to_org":
        return new URLSearchParams({ notificationType, profileID, entityIDs }).toString();

      default:
        return new URLSearchParams({ notificationType, profileID, entityIDs }).toString();
    }
  }

  /**
   * Data un tipo do notifica, ritorna il tipo di interfaccia (il prodotto) da aprire
   * @param notificationType
   * @returns
   */
  appInterfaceFromNotification(notificationType?: NotificationType): "interfaccia_amministroio" | "interfaccia_archivioplus" | "interfaccia_notificoonline" {
    switch (notificationType) {
      default:
        return "interfaccia_amministroio";
    }
  }
}

const fcmHandlerService = makeBinded(FCMHandlerService);

export { fcmHandlerService, FCMHandlerService };
