import { makeBinded } from "@/proxies";
import { httpService } from "@/services/http.service";
import { buildingsEndpointsVO } from "@/vos/buildings/endpoints.vo";
import { httpHeadersVO } from "@/vos/http/headers.vo";
import { pipeAsync } from "@/lib/functions";
import { Profile } from "@amministro-io-packages/auth-interfaces";
import { QuerySequelizeDTO } from "@/dto/query/QuerySequelize.dto";
import { BuildingDTO } from "@/dto/buildings/Building.dto";

class BuildingsRepository {
  constructor(private readonly _http = httpService, private readonly _endpoints = buildingsEndpointsVO, private readonly _headers = httpHeadersVO) {}

  async getOneFromNin(nin: string, getIds = false) {
    return this._http.aGet<Record<string, any>[]>(this._endpoints.findOneFromNin({ nin, getIds }));
  }

  async getOne(profile: Profile, id: string) {
    return this._http.aGet<Record<string, unknown>>(this._endpoints.findOne({ id }), {
      headers: await pipeAsync(this._headers.autenticated, this._headers.json, this._headers.xAmministroProfile(profile))({}),
    });
  }

  async getMany(profile: Profile, q: QuerySequelizeDTO) {
    return this._http.aPost<BuildingDTO[]>(this._endpoints.findMany({}), {
      headers: await pipeAsync(this._headers.autenticated, this._headers.json, this._headers.xAmministroProfile(profile))({}),
      body: JSON.stringify({ data: q }),
    });
  }
}

const buildingsRepository = makeBinded(BuildingsRepository);

export { buildingsRepository, BuildingsRepository };
