import { computed, Ref, ref } from "vue";
import { NotificationFCMDTO, NotificationType, SerializedArray } from "@/dto/notifications/NotificationFCM.dto";
import { fcmListenerService } from "@/services/FCMListener.service";
import { fcmHandlerService } from "@/services/FCMHandler.service";
import { bindingService } from "@/services/bindings.service";
import { useUserStore } from "@/stores/user";

let notificationStatus: Ref<NotificationPermission>;
try {
  notificationStatus = ref<NotificationPermission>(Notification.permission);
} catch {
  notificationStatus = ref("default");
}
const notifications = ref<NotificationFCMDTO[]>([]);
const lastNotification = computed(() => notifications.value.slice(-1)[0] || null);

/**
 * TODO: documentazione
 * @returns
 */
const onNewNotificationCallbacks = ref<
  {
    cb: (not: NotificationFCMDTO) => void;
    name: string;
  }[]
>([]);

const addOnNewNotificationCallback = (cb: { cb: (not: NotificationFCMDTO) => void; name: string }) => {
  onNewNotificationCallbacks.value.push(cb);
};
const removeOnNewNotificationCallback = (name: string) => {
  onNewNotificationCallbacks.value = onNewNotificationCallbacks.value.filter((e) => e.name !== name);
};
/**
 * TODO: documentazione
 * @param msg
 */
const onNewNotification = (msg: CustomEvent<NotificationFCMDTO>) => {
  if (!document.hidden) notifications.value.push(msg.detail);
  if (!document.hidden && window.parent !== window) window.parent.postMessage({ notification: msg.detail }, "*");
  for (const callback of onNewNotificationCallbacks.value) {
    callback.cb(msg.detail);
  }
};

/**
 * TODO: documentazione
 * @param msg
 */
const onNotificationPermissionsChange = (msg: CustomEvent<NotificationPermission & "prompt">) => {
  notificationStatus.value = msg.detail === "prompt" ? "default" : msg.detail;
};
// @ts-expect-error ts(2345)
fcmListenerService.addEventListener("notifications-permission", onNotificationPermissionsChange);

/**
 * TODO: documentazione
 * @returns
 */
const askForNotifications = () => {
  return fcmListenerService.askPermissions();
};

/**
 * TODO: documentazione
 * @returns
 */
const startListeningForNotifications = () => {
  // @ts-expect-error ts(2345)
  fcmListenerService.addEventListener("new-notification", onNewNotification);
  return fcmListenerService.listen();
};

/**
 * TODO: documentazione
 * @returns
 */
const stopListeningForNotifications = () => {
  // @ts-expect-error ts(2345)
  fcmListenerService.removeEventListener("new-notification", onNewNotification);
  return fcmListenerService.stopListening();
};

/**
 * TODO: documentazione
 */
const isFCMSupported = () => fcmListenerService.isSupported();

/**
 * TODO: documentazione
 * @param notification
 */
const openNotification = (notification: NotificationFCMDTO["data"]) => {
  const { notificationType, entityIDs, profileID, notificationBody } = notification;
  handleNotification({
    notificationType,
    entityIDs,
    profileID,
    notificationBody,
  });
};

/**
 *
 * @param notificationType
 * @param entityIDs
 * @param profileID
 * @returns
 */
const handleNotification = (
  p: { notificationType: NotificationType; entityIDs: SerializedArray; profileID: string; notificationBody: string | null },
  target: "_blank" | "_self" = "_blank"
): void => {
  const userStore = useUserStore();
  const { notificationType, profileID } = p;
  const profile = userStore.user!.profiles.find((profile) => profile.profile_id === profileID);
  const appType = fcmHandlerService.appInterfaceFromNotification(notificationType);

  if (!profile || !appType) {
    window.history.replaceState({}, "", `${window.location.origin}/private/launcher`);
  } else {
    const url = bindingService.urlFromApp(profile[appType]);

    const params = new URLSearchParams({
      tkn: userStore.customToken!,
      profile_uid: profile.xAmministroProfile,
      notification: JSON.stringify(p),
    }).toString();

    window.open([url, params].join("?"), target);
  }
};

/**
 *
 * @param notificationType
 * @param entityIDs
 * @param profileID
 * @returns
 */
const handleNotificationEmbedded = (
  p: { notificationType: NotificationType; entityIDs: SerializedArray; profileID: string; notificationBody: string | null },
  parentOrigin: string,
  parentProfileID: string
): void => {
  const userStore = useUserStore();
  const { notificationType, profileID } = p;
  const profile = userStore.user!.profiles.find((profile) => profile.profile_id === profileID);
  const appType = fcmHandlerService.appInterfaceFromNotification(notificationType);

  if (!profile || !appType) {
    window.parent.postMessage({
      msg: "archive-notification",
    });
  } else {
    const url = bindingService.urlFromApp(profile[appType]);

    if (!url) {
      window.parent.postMessage({
        msg: "archive-notification",
      });
    } else {
      if (parentOrigin !== new URL(url).origin || parentProfileID !== profile.profile_id) {
        window.parent.postMessage(
          {
            msg: "forward-notification",
            data: [
              url,
              new URLSearchParams({
                tkn: userStore.customToken!,
                profile_uid: profile.xAmministroProfile,
                notification: JSON.stringify(p),
              }).toString(),
            ].join("?"),
          },
          "*"
        );
      } else {
        window.parent.postMessage(
          {
            msg: "open-notification",
            data: JSON.parse(JSON.stringify(p)),
          },
          "*"
        );
      }
    }
  }
};

export const useFCM = () => ({
  isFCMSupported,
  notificationStatus,
  startListeningForNotifications,
  stopListeningForNotifications,
  askForNotifications,
  handleNotification,
  handleNotificationEmbedded,
  openNotification,
  notifications,
  lastNotification,
  addOnNewNotificationCallback,
  removeOnNewNotificationCallback,
});
