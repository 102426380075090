import { Directive } from "vue";
/**
 * TODO: documentazione
 * TODO: delete and use vuetify v-intersect
 */
export const intersect: Directive<
  HTMLDivElement,
  {
    onVisible: IntersectionObserverCallback;
    onHidden: IntersectionObserverCallback;
  }
> = {
  mounted(el, bindings) {
    const { onHidden, onVisible } = bindings.value;
    const callback: IntersectionObserverCallback = (entries, obs) => {
      const [el] = entries;
      const isVisible = el.isIntersecting;

      if (isVisible && onVisible) {
        onVisible(entries, obs);
      }
      if (!isVisible && onHidden) onHidden(entries, obs);
    };
    const observer = new IntersectionObserver(callback, {
      rootMargin: "0px",
      threshold: 1.0,
    });
    observer.observe(el);
  },
};
